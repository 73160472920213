const Consts = {
    state:{
        ontime:"ontime",
        late:"late",
        absence:"absence",
        leave:"leave",
        unjoin:"unjoin",
    },
	pageState:{
      init:1,
      clickTab:2,
      more:3,
    },
}

export default Consts;