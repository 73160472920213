<template>
  <div class="container">
    <Van-Navbar :showLeftarrow="true" :title="lang.route_coursecheckin">
    </Van-Navbar>
    <div class="checkin">
      <div>
        <h4>{{ courseName }}</h4>
        <div
          class="checkin-students"
          v-for="(item, index) in students"
          :key="index"
          :id="item.admission_no"
        >
          <div class="checkin-students_item">
            <div class="checkin-students_item_info">
              <div class="checkin-students_item_info_detail">
                <!-- <img class="studenthead" :src="item.headImg" /> -->
                <UpdateHeadImg
                  class="studenthead"
                  ref="HeadImg"
                  :defaultImg="item.headImg"
                  :index="index"
                  :type="'student'"
                  @updateImg="updateImg"
                />
                <div class="df_center">
                  <span class="studentname">{{
                    language == "en"
                      ? item["en_name"] || item["cn_name"]
                      : item["cn_name"] || item["en_name"]
                  }}</span>
                  <img
                    :class="['studenthgender', item.gender == 1 ? 'nan' : 'nv']"
                    :src="item.genderImg"
                  />
                  <img
                    class="buke"
                    v-if="item.is_supplement"
                    :src="common.getRes('buke.png')"
                  />
                </div>
              </div>
              <span v-if="!item.is_supplement" class="studenthresidue"
                >{{ lang.Available }}:{{ item.lesson_left }}</span
              >
            </div>
            <van-radio-group
              v-if="!isLook"
              class="checkinstatus"
              v-model="item.attendance_state"
              direction="horizontal"
            >
              <van-radio
                :disabled="isLook"
                :name="s.value"
                v-for="(s, i) in status"
                :key="i"
                >{{ lang[s.lang] }}</van-radio
              >
            </van-radio-group>
            <van-radio-group
              v-else
              class="checkinstatus"
              v-model="item.attendance_state"
              direction="horizontal"
            >
              <van-radio
                :disabled="isLook"
                :name="s.value"
                v-for="(s, i) in oldStatus"
                :key="i"
                >{{ lang[s.lang] }}</van-radio
              >
            </van-radio-group>
          </div>
        </div>
      </div>
      <!-- <div>
        <h4>{{ lang.teacherAttendance }}</h4>
        <div class="teachers">
          <div
            class="teachers_item"
            v-for="teacher in teachers"
            :key="teacher['id']"
          >
            <van-checkbox
              class="checkincheckbox"
              v-model="teacher['attendance_state']"
              :disabled="isLook"
            >
              <span class="teachers_info">
                <img class="teachers_head" :src="teacher.headImg" />
                <span class="teachers_name"> {{ teacher["name"] }}</span>
                <img
                  :class="teacher.gender == 1 ? 'nan' : 'nv'"
                  :src="teacher.genderImg"
                />
              </span>
            </van-checkbox>
          </div>
        </div>
      </div> -->
    </div>
    <div class="footer_btn" v-if="!isLook">
      <van-button
        plain
        type="primary"
        v-show="courseStatus == 1"
        @click="misslesson"
        >{{ lang.route_misslesson }}</van-button
      >
      <van-button type="primary" @click="submit">{{ lang.submit }}</van-button>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, ref } from "vue";
import Common from "@/library/Common";
import request from "@/library/MyRequest";
import Config from "@/library/Config";
import { useRouter } from "vue-router";
import VanNavbar from "@/components/common/van-navbar";
import Consts from "@/library/Consts";
import UpdateHeadImg from "@/components/common/van-updateheadImg";
let _this = this;
export default {
  name: "ClassEvaluation",
  components: {
    VanNavbar,
    UpdateHeadImg,
  },
  props: {
    course: Object,
  },
  mounted() {
    //   赋值 : _this是 this的引用
    _this = this;
  },
  setup(props) {
    const HeadImg = ref(null);
    const common = Common;
    const router = useRouter();
    const consts = Consts;
    const courseData = JSON.parse(router.currentRoute.value.query.courseData);
    let state = reactive({
      oldStatus: [
        {
          label: "出勤",
          lang: "on_time",
          value: consts.state.ontime,
        },
        // {
        //   label: "迟到",
        //   lang: "late",
        //   value: consts.state.late,
        // },
        {
          label: "请假",
          lang: "leave",
          value: consts.state.leave,
        },
        {
          label: "缺勤",
          lang: "absence",
          value: consts.state.absence,
        },
        // {
        //   label: "尚未加入",
        //   lang: "unjoin",
        //   value: consts.state.unjoin,
        // },
      ],
      teacherStatus: [
        {
          label: "出勤",
          lang: "on_time",
          value: consts.state.ontime,
        },
        // {
        //   label: "请假",
        //   lang: "leave",
        //   value: consts.state.leave,
        // },
        // {
        //   label: "缺勤",
        //   lang: "absence",
        //   value: consts.state.absence,
        // },
      ],
      status: [
        {
          label: "出勤",
          lang: "on_time",
          value: consts.state.ontime,
        },
        {
          label: "请假",
          lang: "leave",
          value: consts.state.leave,
        },
        {
          label: "缺勤",
          lang: "absence",
          value: consts.state.absence,
        },
      ],
      lang: {
        route_coursecheckin: "",
        Itcannotbemodifiedaftersubmission: "",
        yes: "",
        no: "",
        submit_type_success: "",
        networkError: "",
        on_time: "",
        late: "",
        absence: "",
        leave: "",
        unjoin: "",
        submit: "",
        route_misslesson: "",
        Available: "",
        confirm_checkin: "",
        cancel: "",
        determine: "",
        addstudent: "",
        pleaseselect: "",
        teacherAttendance: "",
      },
      students: [],
      teachers: [],
      teacherIds: [],
      finished: false,
      courseName: courseData.courseName,
      courseStatus: courseData.lesson_status,
      courseType: courseData.courseType,
      isLook: courseData.isLook,
      language: common.getLocal("Language"),
    });
    common.initLanguage(state.lang);
    console.log("courseId", courseData);
    const getLesson = async () => {
      var res = await request.get(
        `/lesson/list-student?lesson_id=${courseData.courseId}`
      );
      console.log("student", res);
      state.students = res.data.students;
      state.teachers = res.data.teachers;
      state.students.map((item) => {
        item["headImg"] =
          item.avatar_url == null
            ? common.getRes("default_headImg.png")
            : item.avatar_url;
        // : Config["imgUrl"] + item.avatar_url;
        item["genderImg"] = common.getRes(
          `shuyi_${item.gender === 1 ? "nan" : "nv"}sheng.png`
        );
        item["attendance_state"] =
          item["attendance_state"] == "late"
            ? "ontime"
            : item["attendance_state"];
      });
      // state.teachers.map((item) => {
      //   item["headImg"] =
      //     item.avatar_url == null
      //       ? common.getRes("default_headImg.png")
      //       : item.avatar_url;
      //   item["attendance_state"] = item["attendance_state"] == "ontime";
      //   item["genderImg"] = common.getRes(
      //     `shuyi_${item.gender === 1 ? "nan" : "nv"}sheng.png`
      //   );
      // });
    };
    const submit = () => {
      console.log("state.students", state.students);
      if (state.students.length == 0) {
        _this.$toast({
          forbidClick: true,
          message: state.lang.addstudent,
          onClose: () => {},
        });
        return;
      }
      if (!checkAttendanceAtate()) return;
      _this.$dialog
        .confirm({
          title: state.lang.confirm_checkin,
          confirmButtonText: state.lang.determine,
          cancelButtonText: state.lang.cancel,
        })
        .then(async () => {
          sendData();
        });
    };
    const sendData = async () => {
      let students = state.students.map((item) => {
        return {
          id: item.admission_no,
          state: item.attendance_state,
          info: item.info ? item.info : "",
        };
      });
      // let teachers = state.teachers.map((item) => {
      //   return {
      //     id: item.id,
      //     state: item.attendance_state ? "ontime" : "absence",
      //     info: item.info ? item.info : "",
      //   };
      // });
      let send = {
        lesson_id: courseData.courseId,
        students: students,
        // teachers: teachers,
      };
      console.log("send", send);
      // return;
      try {
        var res = await request.post(`lesson/create-attendance/`, send);
        _this.$toast({
          forbidClick: true,
          message: state.lang.submit_type_success,
          onClose: () => {},
        });
        router.push({
          path: `/comment/student`,
          query: {
            courseData: JSON.stringify({
              courseId: courseData.courseId,
              currentPath: courseData.currentPath,
            }),
          },
        });
        // router.push("/course");
      } catch (e) {
        _this.$toast(state.lang.networkError);
        console.log(e);
      }
    };
    const updateImg = async (obj) => {
      console.log("eupdateImg", obj, state.students[obj.index]);
      let data = new FormData();
      data.append("avatar_url", obj.name);
      data.append("admission_no", state.students[obj.index].admission_no);
      const res = await request.put(`student/update-avatar/`, data);
      Toast({
        forbidClick: true,
        message: state.lang.submit_type_success,
      });
    };
    const misslesson = () => {
      router.push({
        path: `/misslesson`,
        query: {
          courseData: JSON.stringify(courseData),
        },
      });
    };
    const checkAttendanceAtate = () => {
      let isPass = true;
      if (isPass) {
        state.students.map((item) => {
          if (
            (item.attendance_state == null ||
              item.attendance_state == undefined ||
              item.attendance_state == "") &&
            isPass
          ) {
            _this.$toast({
              forbidClick: true,
              message: state.lang.pleaseselect,
            });
            //顶部
            document.getElementById(item.admission_no).scrollIntoView();
            isPass = false;
          }
        });
      }
      return isPass;
    };
    getLesson();
    return {
      ...toRefs(state),
      common,
      getLesson,
      misslesson,
      submit,
      sendData,
      updateImg,
      checkAttendanceAtate,
      HeadImg,
    };
  },
};
</script>

<style lang="less" scoped>
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/deep/.van-radio__icon--disabled.van-radio__icon--checked .van-icon {
  color: #a5907e;
}

.checkin {
  flex: 1;
  padding: 20px;
  overflow-y: auto;

  h4 {
    margin: 0;
    text-align: left;
    padding-bottom: 30px;
    font-size: 34px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 48px;
  }

  .checkin-students {
    padding: 26px 20px 30px;
    background-color: #fff;
    border-radius: 8px;
    margin-bottom: 20px;

    .checkin-students_item {
      .checkin-students_item_info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: PingFangSC-Regular, PingFang SC;
        margin-bottom: 30px;

        .df_center {
          display: flex;
          align-items: center;
        }

        .checkin-students_item_info_detail {
          display: flex;
          align-items: center;
          height: 56px;
          line-height: 56px;
        }

        .studenthead {
          width: 56px;
          height: 56px;
          border-radius: 50%;
          margin: 0px;
        }

        .studentname {
          font-size: 32px;
          font-weight: 500;
          color: #000000;
          // line-height: 30px;
          padding: 0 16px;
        }

        .studenthgender {
          // width: 100%;
          // width: 22px;
          // height: 32px;
          padding: 0 10px;
        }

        .buke {
          width: 56px;
          height: 30px;
        }

        .studenthresidue {
          font-size: 26px;
          font-weight: 400;
          color: #a5907e;
          line-height: 36px;
        }
      }

      .checkinstatus {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 0 20px;
      }
    }
  }
  .teachers {
    padding: 20px;
    box-sizing: border-box;
    border-radius: 6px;
    background-color: #fff;
    .teachers_info {
      display: flex;
      align-items: center;

      .teachers_head {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        margin: 0px;
      }
      .teachers_name {
        margin: 0 16px;
      }
    }
    .checkincheckbox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row-reverse;
      margin-bottom: 20px;
    }
  }
}

.footer_btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 20px 20px;
  background-color: #fff;
  box-shadow: 0px -2px 7px 0px rgba(0, 0, 0, 0.15);

  /deep/.van-button {
    height: 88px;
    border-radius: 12px;
    font-size: 32px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 44px;
    padding: 20px 40px;

    &:nth-child(1) {
      border-color: #a5907e;
      color: #a5907e;
      margin-right: 20px;
    }

    &:nth-child(2) {
      flex: 1;
      background-color: #a5907e;
      color: #fff;
      border-color: #a5907e;
    }
  }
}

/deep/.van-radio--horizontal {
  // width: 33.33%;
  // margin-bottom: 20px;
  margin-right: 0;
  padding-right: 10px;
  box-sizing: border-box;

  .van-radio__label {
    white-space: nowrap;
  }
}

</style>
